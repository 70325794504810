<template>
  <div>
    <div id="chart-container" class="by-moneyball-stick">
      <div v-show="this.$parent.getDisplayView() && getDisplayView()" id="cp-by-moneyball-stick-e-chart" class="chart-row">
        <profile-chart-header
            :title="'Moneyball Stick'"
            :display="this.$parent.getDisplayView() && getDisplayView()"
            :input-display-number-of-responses="eChartCriteria.displayNumberOfResponses"            
            :display-show-respondents="false"
            @set-display-number-of-responses="setDisplayNumberOfResponses"
        />       
        <moneyball-stick-chart ref="eChart"
            :e-chart-mini-id="'moneyball-stick-mini'"
            :e-chart-id="'moneyball-stick'"
            :input-class-name="'e-chart'"
            :input-report-type-name="'Chart - Moneyball Stick'"
            :modal-id="'profile-by-moneyball-stick-chart'"    
            :is-saved-card="$parent.clientChart.clientChartId != 0" 
            :input-client-chart="this.$parent.clientChart"
            :inputDownloadFileName="'Moneyball Stick'"
            @save-table="saveProfileDataTable"
            @save-criteria="buildClientChart"/>     
      </div>      
      <div v-show="this.$parent.getDisplayView() && getDisplayView()" :id="moneyballStickTable.tableContainerId" :class="isInsiderUser ? 'chart-data hide-table-data' : 'chart-data'">
        <div v-show="isInsiderUser" class="overlay">
          <img class="overlay-inner" v-show="isInsiderUser" src="../../../assets/img/overlap.png" style="width: 50%"/>
        </div>
        <chart-data-table
          ref="moneyballStickDataTable"
          v-show="this.$parent.getDisplayView() && getDisplayView()"
          :chart-data="this.moneyballStickTable.data"
          :display-direction="'vertical'"
          :data-container-id="moneyballStickTable.tableContainerId"
          :container-id="'cp-by-moneyball-stick-chart'"
          :is-saved-card="$parent.clientChart.clientChartId != 0"           
          :content-list="this.pageContentForMoneyballStick"
          :column-defs="this.chart.columnDefs"
          :table-class="'table display row-border table-hover'"
          :table-width="'auto'"
          :report-page-type-name="'Table - Moneyball Stick'"
          :insight-modal-id="'profile-by-moneyball-stick-table'"
          :table-title="this.moneyballStickTable.title"
          :inputDownloadFileName="'Moneyball Stick'"
        ></chart-data-table>
      </div>
    </div>
    <div v-for="(pageContent, idxPageContent) in pageContentForMoneyballStick" v-bind:key="idxPageContent">
      <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
    </div>
    
    <insights
      ref="insightsChart"
      :modal-name="'profile-by-moneyball-stick-chart'"
      :report-page-type-name="'Chart - Moneyball Stick'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="$parent.updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
    <insights
      ref="insightsTable"
      :modal-name="'profile-by-moneyball-stick-table'"
      :report-page-type-name="'Table - Moneyball Stick'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="$parent.updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="$parent.saveClientChart"
    />
  </div>
</template>
<script>
import $ from "jquery";
import ProfileChartHeader from "../../../components/echarts/ProfileChartHeader.vue";
import MoneyballStickChart from "../../../views/echarts/MoneyballStickChart.vue";

import ChartCompanyMixins from "../../../mixins/ChartCompanyMixins.vue";
import ChartService from "../../../services/chart.service";
import ErrorService from "../../../services/error.service";
import AdminService from "../../../services/admin.service";
import ReportService from "../../../services/report.service";
import ContentService from "../../../services/content.service";

export default {
  name: "ByMoneyballStick",
  mixins: [ChartCompanyMixins],
  components: { ProfileChartHeader, MoneyballStickChart},
  data() {
    return {
      displayType: "moneyball-stick",
      pageContentForMoneyballStick: [],
      moneyballStickTable: {
        id: "",
        data: {},
        title: '<div class="title"><h4 class="text-primary">Moneyball Stick</h4></div>',
        tableContainerId: "moneyball_stick_needs_data_container",
      },
      isMobile: false
    };
  },
  props: {
    chartData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    contentList: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    surveyCode: function() {
      return this.$parent.$data.survey.surveyCode;
    },    

  },
  mounted() {},
  created() {
        ContentService.getPageContent("Moneyball Stick", this.getPageContentByMoatsCallBack, this);
  },
  methods: {
    getPageContentByMoatsCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.pageContentForMoneyballStick = response.data.data;
      }
    },
    displayInsights(insightModalId, reportPageTypeName) {
      if (reportPageTypeName == "Chart - Moneyball Stick") {
        this.$refs.insightsChart.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsChart.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      } else if (reportPageTypeName == "Table - Moneyball Stick") {
        this.$refs.insightsTable.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.$parent.reportPagesList);
        this.$refs.insightsTable.initializeInsights(this.$parent.clientChart);
        this.$modal.show(insightModalId);
      }
    },
    setDisplayNumberOfResponses(inputDisplayNumberOfResponses){
      this.eChartCriteria.displayNumberOfResponses = inputDisplayNumberOfResponses;
      this.$parent.$refs.chartCriteria.eChartCriteria.displayNumberOfResponses = inputDisplayNumberOfResponses;      
      this.processChartData();
    },
    async processChartData() {
      try {
        //console.log("ByMoneyballStick.processChartData", this.chartData);
        this.setEChartCriteria(this.$parent.$refs.chartCriteria.eChartCriteria);        
        this.chart = ChartService.getChartAttributes("Profile By Moneyball Stick");
        let needsData = JSON.parse(JSON.stringify(this.chartData[0]));        
        let otherData = needsData["otherData"];
        let moneyballStickResults = otherData["moneyballStickResults"];
        if (typeof this.eChartCriteria.displayNumberOfResponses != "undefined" && 
            this.eChartCriteria.displayNumberOfResponses != null &&
            this.eChartCriteria.displayNumberOfResponses == true) { 
              moneyballStickResults.xAxisData = JSON.parse(JSON.stringify(moneyballStickResults.xAxisDataWithNumberOfRespondents));
        } 
        this.$refs.eChart.processChart(true,moneyballStickResults,false);
        let containerId = needsData["containerId"];
        this.table.id = containerId;
        let copyOfChartData = JSON.parse(JSON.stringify(needsData));
        this.moneyballStickTable.id = containerId;
        this.moneyballStickTable.data = copyOfChartData;

        this.$parent.setChartResults(needsData.companyList);
        this.$parent.$data.criteriaResultsList = AdminService.removeByName(needsData.criteriaList, "Competitor");

        $("#cp-by-moneyball-stick-e-chart").addClass("chart-wrapper");
        this.setDisplayView(true);
        this.$parent.setDisplayView(true);
      } catch (err) {
        ErrorService.processException("ByMoneyballStick.displayChartCallback", err, "chart", this);
      }
    },
    async saveProfileDataTable(){
      if (this.$parent.clientChart.clientChartId != 0 && this.isGenerateReportRunning()) {
        let vm = this;
        setTimeout(function() {
          ReportService.saveReportTableFile(vm.$refs.moneyballStickDataTable.getTableData(), "Table - Moneyball Stick", vm.$parent.clientChart, vm.saveReportTableFileCallback,vm);
          ReportService.saveReportPage(vm.getReportTableContent("cp-by-moneyball-stick-chart"),"Table - Moneyball Stick", vm.$parent.clientChart, vm.saveReportPageCallback, vm);
        }, 1500);        
      }
    },
    saveAllReportPages() {
        this.saveProfileDataTable();
    },
  },
  watch: {
    surveyCode: function() {
      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try{this.$refs.eChart.clear();} catch(e){}
    },
    chartData: function() {
      if (this.$parent.isByMoneyballStick) {
        this.processChartData();
      } else {
        this.setDisplayView(false);
      }
    }
  }
};
</script>
